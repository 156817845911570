import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title='my-Instgram' link='https://adel-abdallah.github.io/my-instagram' bg='#484848' mdxType="ProjectCard">
  This project is sample instagram like app you can upload photos and it will be
  saved and showen on your homepage. #React #Firebase
    </ProjectCard>
    <ProjectCard title='Pizza Shop' link='https://gafo-pizza-shop.netlify.app/' bg='#484848' mdxType="ProjectCard">
  Pizza Shop a very nice small project that has a lot of good animation done by
  framer-motion framer-motion is a library for react to add animation in an easy
  way.
    </ProjectCard>
    <ProjectCard title='chat-app' link='https://gafo-chat-app.netlify.app/' bg='#484848' mdxType="ProjectCard">
  a chat ap use React on the Fron-End and Chat-Engine as the Back-End Chat
  Engine is an API which makes it easy to build chat services. Building a chat
  from scratch takes a lot of time, code, and is expensive.
    </ProjectCard>
    <ProjectCard title='crown-store' link='http://crown-gafo-store.herokuapp.com/' bg='#484848' mdxType="ProjectCard">
  This project is my entry to React Firebase and Redux i used a lot of best
  practices in the clothing online-store. #React #Redux #Firebase
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      