import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I help designers, small agencies and businesses bring their ideas to life. Powered by React, I turn your requirements into websites - on time and on budget.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/Adel-Abdallah"
      }}>{`check my github`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      